import React from "react"
import styled from "@emotion/styled"
import SEO from "../components/seo"
import Location from "../components/location"

const Hero = styled.div`
  min-height: 100%;
`
const StyledH1 = styled.h1``

const ContactSection = styled.section`
  position: relative;
  z-index: 1;
  margin-bottom: 15%;
  @media (min-width: 768px) {
    margin-bottom: 10%;
  }
`

const NewsletterSection = styled.section`
  position: relative;
  z-index: 1;
  @media (min-width: 768px) {
  }
`

const canSubscribeToNewsletter = false

export default function Page({
  data, // this prop will be injected by the GraphQL query below.
}) {
  return (
    <>
      <SEO
        title="Contact"
        description="We are based in Amsterdam and London. Read more for contact details. You can always drop us a line on amsterdam@chunkagency.com or london@chunkagency.com."
      />

      <section className="section-header position-relative">
        <Hero>
          <div className="container-fluid d-flex flex-column justify-content-center">
            <div className="row">
              <div className="col-12 text-center">
                <StyledH1 className="display-2 font-weight-bolder mb-1 mb-md-2 mb-lg-4">
                  <span>Contact</span>
                </StyledH1>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-7 text-center">
                <p></p>
              </div>
            </div>
          </div>
        </Hero>
      </section>

      {canSubscribeToNewsletter && (
        <NewsletterSection>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12"></div>
            </div>
          </div>
        </NewsletterSection>
      )}

      <ContactSection className="">
        <div className="container-fluid">
          <div className="row d-flex justify-content-between">
            <div className="col-12 col-lg-6 text-lg-right">
              <h5 className="h5 text-uppercase mt-3 mt-lg-0 mb-1 mb-lg-3 font-weight-bolder">
                Amsterdam
              </h5>
              <p className="font-weight-light text-uppercase">
                <a
                  className="nav-link nav-link--location black font-weight-light p-0 link--line-under"
                  href="https://www.google.nl/maps/place/Chunk+Creative+Agency/@52.3754919,4.9272503,17z/data=!4m13!1m7!3m6!1s0x47c6090f5304cb47:0xf7773de192ed1519!2sOostelijke+Handelskade+527,+1019+BM+Amsterdam!3b1!8m2!3d52.3754919!4d4.929439!3m4!1s0x0:0x2b633434d88c700a!8m2!3d52.3754363!4d4.9295662"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="icon--location">
                    <Location />
                  </span>
                  <span>Oostelijke Handelskade 527</span>
                </a>
                <br />
                1019 BW Amsterdam
                <br />
                <a
                  className="nav-link p-0 font-weight-light black link--line-under"
                  href="mailto:amsterdam@chunkagency.com"
                >
                  amsterdam@chunkagency.com
                </a>
              </p>
            </div>
            <div className="col-12 col-lg-6 text-left">
              <h5 className="h5 text-uppercase mt-3 mt-lg-0 mb-1 mb-lg-3 font-weight-bolder">
                London
              </h5>
              <p className="font-weight-light text-uppercase">
                <a
                  className="nav-link nav-link--location nav-link--location-inverse black font-weight-light p-0 link--line-under"
                  href="https://www.google.nl/maps/place/79+Borough+Rd,+London+SE1+1DN,+Verenigd+Koninkrijk/@51.4989667,-0.1004165,17z/data=!3m1!4b1!4m5!3m4!1s0x487604a1357a7373:0x71eadc930c9c334a!8m2!3d51.4989667!4d-0.0982225"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="icon--location">
                    <Location />
                  </span>
                  <span>Borough Road 79-81</span>
                </a>
                <br />
                1UV London
                <br />
                <a
                  className="nav-link font-weight-light p-0 black link--line-under"
                  href="mailto:london@chunkagency.com"
                >
                  london@chunkagency.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </ContactSection>
    </>
  )
}
